/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'box-arrow-in-left': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M10 3.5a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2A1.5 1.5 0 019.5 14h-8A1.5 1.5 0 010 12.5v-9A1.5 1.5 0 011.5 2h8A1.5 1.5 0 0111 3.5v2a.5.5 0 01-1 0z"/><path pid="1" fill-rule="evenodd" d="M4.146 8.354a.5.5 0 010-.708l3-3a.5.5 0 11.708.708L5.707 7.5H14.5a.5.5 0 010 1H5.707l2.147 2.146a.5.5 0 01-.708.708z"/>',
    },
});
